import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { useUlysses } from '@smarterlabs/ulysses'
import RemoveIcon from '@meronex/icons/ai/AiFillDelete'
import color from '@app/dynamic-color'

export default function LineItem({ lineItem }){
	const ulysses = useUlysses()
	const [quantityInput, setQuantityInput] = useState(lineItem.quantity)

	function decrease(e){
		e.preventDefault()
		ulysses.adjustQuantity(lineItem.sku, -1)
	}
	function increase(e){
		e.preventDefault()
		ulysses.adjustQuantity(lineItem.sku, 1)
	}
	function remove(e){
		e.preventDefault()
		ulysses.remove(lineItem.sku)
	}
	function changeQuantity(){
		const quantity = Number(quantityInput)
		if (!Number.isNaN(quantity)) {
			ulysses.setQuantity(lineItem.sku, quantity)
		}
	}
	useEffect(() => {
		setQuantityInput(lineItem.quantity)
	}, [lineItem.quantity])

	let description = lineItem.description || ``
	description = description.split(`\n`).join(`<br />`)

	return(
		<div css={styles.row}>
			<div css={styles.imageContainer}>
				{lineItem.image && (
					<img src={lineItem.image} alt={lineItem.title} />
				)}
			</div>
			<div css={styles.copy}>
				<h2 css={styles.title}>{lineItem.title}</h2>
				<div dangerouslySetInnerHTML={{__html: description}} />
			</div>
			<div css={styles.quantity}>
				<div css={styles.quantityInner}>
					{!lineItem.singleQuantity && (
						<>
							<button disabled={ulysses.isLoading} onClick={decrease}>-</button>
							<input
								disabled={ulysses.isLoading}
								onBlur={changeQuantity}
								onChange={e => setQuantityInput(e.target.value)}
								onKeyDown={e => {
									if (e.keyCode == 13) {
										changeQuantity()
									}
								}}
								value={quantityInput}
								type='number'
							/>
							<button disabled={ulysses.isLoading} onClick={increase}>+</button>
						</>
					)}
					<button disabled={ulysses.isLoading} onClick={remove} css={styles.removeIcon}>
						<RemoveIcon />
					</button>
				</div>
			</div>
		</div>
	)
}

const styles = {
	removeIcon: css`
		background: transparent !important;
		padding: 2px;
		border: 0;
		color: ${color(`black`, `black`)};
		margin-left: 5px;
		svg{
			width: 70%;
			height: 70%;
			position: relative;
			top: 4px;
		}
		:hover{
			opacity: .7;
		}
	`,
	copy: css`
		width: 100%;
	`,
	quantityInner: css`
		display: inline-block;
	`,
	quantity: css`
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		button{
			min-width: 30px;
			width: 30px;
			font-weight: bold;
			background: transparent;
			padding: 0;
			outline: 0;
			border: 0;
			height: auto;
			width: auto;
			text-shadow: none;
			color: #000;
		}
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type=number] {
			-moz-appearance: textfield;
		}
		input{
			text-align: center;
			width: 50px;
		}
	`,
	row: css`
		display: flex;
		flex-wrap: nowrap;
		margin: 10px -10px;
		> *{
			margin: 10px;
		}
	`,
	imageContainer: css`
		max-width: 150px;
		display: none;
		@media(min-width: 500px){
			display: block;
		}
	`,
	title: css`
		margin: 0;
		margin-bottom: 5px;
	`,
}