import React from 'react'
import { css } from '@emotion/core'
import LineItem from './line-item'
import { useUlysses } from '@smarterlabs/ulysses'
import cartTemplate from '@data/sanity/cartTemplate.json'
import get from 'lodash/get'
import Loading from '@app/loading'
import Head from '@app/head'
import Modules from '../sanity-module'

const title = get(cartTemplate, `title`, `Your Cart`)
const headerModules = get(cartTemplate, `headerModules`, [])
const footerModules = get(cartTemplate, `footerModules`, [])

export default function Cart(){
	const ulysses = useUlysses()
	const hasItems = ulysses.lineItems.length > 0
	console.log(`Cart ulysses`, ulysses)

	function handleCheckout(e){
		e.preventDefault()
		ulysses.checkout()
	}

	return (
		<div css={styles.wrapper}>
			<Head title={title} />
			<Modules
				pageTitle={title}
				modules={headerModules}
			/>
			<h1>{title}</h1>

			{hasItems && (
				<>
					<div>
						{ulysses.lineItems.map((item, index) => {
							return (
								<LineItem key={item.sku || index} lineItem={item} />
							)
						})}
					</div>

					<div css={styles.right}>
						<div css={styles.totals}>
							<h3 css={styles.totalLabel}>Total</h3>
							<div css={styles.total}>${ulysses.totalPrice}</div>
						</div>
						<div css={styles.notice}>Shipping, coupons, and taxes will be calculated at checkout.</div>
						<button css={styles.checkoutButton} onClick={handleCheckout} disabled={ulysses.isLoading}>Check out</button>
					</div>
				</>
			)}
			{!hasItems && !ulysses.isInitiating && (
				<>
					<div>Your cart is empty.</div>
				</>
			)}
			{ulysses.isInitiating && (
				<Loading />
			)}
			<Modules
				pageTitle={title}
				modules={footerModules}
			/>
		</div>
	)
}

const styles = {
	wrapper: css`
		margin-bottom: 30px;
	`,
	notice: css`
		font-size: .8em;
		margin-bottom: 10px;
	`,
	checkoutButton: css`
		width: 100%;
	`,
	right: css`
		margin: 30px 0;
		@media(min-width: 500px){
			max-width: 290px;
			margin-left: auto;
		}
	`,
	totals: css`
		> *{
			display: inline-block;
			width: 50%;
		}
	`,
	totalLabel: css`
		font-weight: bold;
	`,
	total: css`
		text-align: right;
	`,
}