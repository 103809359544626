import React from 'react'
import Cart from '../components/cart'
import LayoutWrapper from '@app/layout-wrapper'
import Head from '@app/head'

export default function CartPage(){
	return(
		<LayoutWrapper>
			<Head title={`Cart`} />
			<Cart />
		</LayoutWrapper>
	)
}
